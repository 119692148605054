import React from "react";
import { SEO, MarkdownContent, Accordion } from "@bluefin/components";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class FaqsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-faqs custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"puddgzvniexwbcpy"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ieskrckgwjujosxv"}
              style={{ padding: 16 }}
              width={9}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "399a3a0f-0144-4aae-b338-3697cc99021b",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"maymkonmdceaifho"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ncxxjgqcirqululi"}
              style={{ padding: 16, background: "#f3f1e2" }}
              width={12}
              textAlign={"justified"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Accordion
                  className={""}
                  style={{}}
                  styled={true}
                  exclusive={true}
                  defaultActiveIndexes={[]}
                >
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "5d862865-f2be-4d14-9005-8e238a7e193f",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "fed431f6-2a9e-485f-8922-74b715cddfa7",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "df7ea4b1-59c3-4944-bede-6b351233c057",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "b41d666e-24ba-4554-b6f1-53420b64f8af",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "8068cc05-2d0e-4772-a950-8a031b5b2a45",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "e7096ad8-aef1-4a7b-878d-65969d687d3b",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27604 }) {
      title
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
